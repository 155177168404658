/* Style for each response element in the list */
.response-container {
  margin-bottom: 10px;
  color: white;
  padding: 10px 10px;
  font-size: 1rem;
  display: flex;
  align-items: center;
}

.response-container .avatar-image {
  width: 60px;
  height: 60px;
  margin-right: 25px;
  border-radius: 60px;
}

.response-container .response-content {
  display: flex;
  flex-direction: column;
}

.response-container pre {
  max-width: 100%;
  margin: 0 !important;
  white-space: break-spaces;
}

.my-question > .prompt-content {
  padding: 0px 24px;
  border-radius: 30px;
  background: blue;
}

.chatgpt-response > .prompt-content {
  background: lightgrey;
  color: black;
  padding: 0px 24px;
  border-radius: 30px;
  margin-top: 5px;
}

.response-container .chatgpt-response .prompt-content p:first-child {
  margin-top: 0;
}

.ai-image {
  width: 500px;
  height: auto;
}

.error-response {
  color: rgb(220, 0, 0) !important;
}

/* Override hljs to match for chatgpt */
.hljs {
  background: rgb(0, 0, 0) !important;
  color: white !important;
  display: block;
  padding: 10px;
  border-radius: 6px;
}

.hljs-section,
.hljs-title {
  color: #f22c3d !important;
}

.hljs-deletion,
.hljs-number,
.hljs-quote,
.hljs-selector-class,
.hljs-selector-id,
.hljs-string,
.hljs-template-tag,
.hljs-type {
  color: #df3079 !important;
}

.hljs-addition,
.hljs-built_in,
.hljs-bullet,
.hljs-code {
  color: #e9950c !important;
}

.hljs-link,
.hljs-operator,
.hljs-regexp,
.hljs-selector-attr,
.hljs-selector-pseudo,
.hljs-symbol,
.hljs-template-variable,
.hljs-variable {
  color: white !important;
}
