html,
body,
#root,
.App {
  height: 100%;
}
.App {
  display: flex;
  flex-direction: column;
}
/* Add background color to the body */
body {
  background-color: white;
  margin: 0;
  font-family: 'Inter', sans-serif;
}

/* Remove border color from the response list container */
#response-list {
  border: none;
  overflow-y: auto;
  flex: 1;
}

.chatgpt-response {
  background-color: ;
}

.my-question {
  justify-content: flex-end;
}
/* Change background color of the input container */
#input-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-color: rgba(32, 33, 35, 0.5);
  border-radius: 5px;
  margin: 10px 10px;
}

/* Remove background color and add an SVG background for the submit button */
#submit-button {
  background: transparent
    url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'><path fill='rgba(64, 65, 79, 1)' d='M2.01 21L23 12 2.01 3 2 10l15 2-15 2z'/></svg>")
    no-repeat center center;
  color: rgba(64, 65, 79, 1);
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

#regenerate-button-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 10px;
}

#regenerate-response-button {
  color: rgba(64, 65, 79, 1);
  border: none;
  background: #10a37f;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
}

.loading {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}

.select-theme {
  margin-top: 20px;
}

.select-theme p {
  margin: 0 0 5px 0;
  padding: 0;
}

.radio-label {
  color: #303030;
  font-size: 14px;
  font-weight: 400;
  margin-right: 7px;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
}

.radio-input {
  margin: 0;
  visibility: hidden;
}

.radio-input:checked + span {
  border: 2px solid #f4b400;
}

.radio-input:checked + span:after {
  opacity: 1;
}

.custom-radio {
  left: -8px;
  top: 6px;
  cursor: pointer;
  width: 17px;
  height: 17px;
  border: 2px solid #9a9a9a;
  border-radius: 50%;
  display: inline-block;
  position: relative;
}

/* for inner filled circle */
.custom-radio::after {
  content: '';
  width: 12px;
  height: 12px;
  background: #f4b400;
  position: absolute;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.2s;
}

.drawer {
  padding: 40px;
  overflow-y: auto;
}
