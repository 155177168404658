/* Style for the prompt input */
#prompt-input {
  flex-grow: 1;
  padding: 10px;
  border-radius: 5px;
  min-height: 20px;
  color: rgba(64, 65, 79, 1);
  overflow: auto;
}

#prompt-input:focus {
  outline: none !important;
}
